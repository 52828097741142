import React, { useEffect } from 'react';
import './Contact.css'; // Importing custom CSS for complex styles

function Contact() {
    useEffect(() => {
        const contactSection = document.querySelector('.contact');
        contactSection.style.opacity = 1;
        contactSection.style.transform = 'translateY(0)';
    }, []);
    return (
        <section className="contact">
          <div id="contact" className="contact-content">
            <h1>Contact Me</h1>
            <p>Feel free to get in contact, im looking for work.</p>
            <div className="flex justify-center space-x-8">
              <a href="mailto:llyrdavies047@outlook.com"><i className='bx bxs-envelope text-4xl'></i></a>
              <a href="https://github.com/llyrdavies"><i className='bx bxl-github text-4xl'></i></a>
            </div>
          </div>
        </section>
      );
}
export default Contact;

