import React, { useEffect } from 'react';
import './Aboutme.css'; // Importing custom CSS for complex styles

function Aboutme() {
    useEffect(() => {
        const aboutmeSection = document.querySelector('.aboutme');
        console.log('Aboutme section styles before:', aboutmeSection.style.opacity, aboutmeSection.style.transform);
        aboutmeSection.style.opacity = 1;
        aboutmeSection.style.transform = 'translateY(0)';
        console.log('Aboutme section styles after:', aboutmeSection.style.opacity, aboutmeSection.style.transform);
    }, []);
    return (
        <section className="aboutme">
          <div id="aboutme" className="aboutme-content">
            <h1>About Me</h1>
            <p>Hey there! I'm a self-taught full-stack developer from Wales with a passion for building web applications. My tech stack primarily includes Ruby on Rails (RoR) and ReactJS, depending on the complexity of the project. I almost always style with TailwindCSS, unless a client has other preferences. For databases, I typically use PostgreSQL, but I occasionally turn to MongoDB when speed is a priority. Beyond coding, I'm a dedicated problem solver who thrives on creating and learning. My ultimate goal? To launch a successful startup and turn ideas into impactful products.</p>
            <div className="flex justify-center space-x-8 mt-4">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Ruby_On_Rails_Logo.svg/2560px-Ruby_On_Rails_Logo.svg.png" alt="Ruby on Rails" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" alt="React" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2560px-Tailwind_CSS_Logo.svg.png" alt="Tailwind CSS" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/993px-Postgresql_elephant.svg.png" alt="PostgreSQL" className="h-10" />
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png" alt="MongoDB" className="h-10" />
            </div>
          </div>
        </section>
      );
}
export default Aboutme;
