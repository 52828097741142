import React, { useEffect } from 'react';
import './Projects.css'; // Ensure the CSS file is updated as well

function Projects() {
    useEffect(() => {
        const projectsSection = document.querySelector('.projects-container');
        projectsSection.style.opacity = 1;
        projectsSection.style.transform = 'translateY(0)';
    }, []);
  const projects = [
    { id: 1, title: 'Ammo.lol', description: 'A highly customizable and feature rich biolink SaaS platform similar to linktree, created in 2 weeks. It includes a powerful admin panel to manage the application dynamically and an API.', image: 'https://i.imgur.com/P8GCeS4.png', url: 'https://ammo.lol' },
    { id: 2, title: 'MedFinals', description: 'A complete medical educational platform built for med students in university or college, features an incredible looking and responsive UI, full admin panel, and so much more.', image: 'https://i.imgur.com/3I3XVH0.png', url: 'https://medfinals.com' },
    { id: 3, title: 'DarkSide Payments', description: 'A complete custom payment solution build for the DarkSide Panel SMM Panel, has a fully functional admin panel and admin API and complete stripe api integration.', image: 'https://i.imgur.com/G6ikDpy.png', url: 'https://payments.darksidepanel.com' },
    { id: 4, title: 'Free Panel', description: 'A website that allows users to request likes, views, followers etc for various social media platforms completely free of charge, however i only wrote the backend for this project, the frontend was created by a friend.', image: 'https://i.imgur.com/jzzh7gK.png', url: 'https://free.darksidepanel.com' },
    { id: 5, title: 'Dyligen Cloud', description: 'A game server hosting company, the web application itself wasnt built by me however i still run and maintain all infustructure myself as a system admin along with my staff.', image: 'https://i.imgur.com/7ihRmfZ.png', url: 'https://dyligen.com' },
    { id: 6, title: 'DarkSide Panel', description: 'My first sucessful business, the website / panel itself was not created by me instead it was simply licensed. I ran and maintained this smm panel and grew it to over 150k users. This tought me several useful skills when it came to running a business.', image: 'https://i.imgur.com/DS6HZDT.png', url: 'https://darksidepanel.com' },
    { id: 7, title: 'Llyrdavies.online', description: 'As soon as i got some experience using rails i rewrote my original llyrdavies.online website in rails, the website includes features such as AI chatbots, hosting of singleplayer and multiplayer games, online real time chat rooms, admin panel, etc', image: 'https://i.imgur.com/eiv9smU.png', url: 'https://llyrdavies.online' },
    { id: 8, title: 'Flyte Panel', description: 'An unfinished project that I was working on, a complete saas solution allowing users to create and deploy their own smm panels in their own branding in minutes using automated cloud infustructure and a custom smm panel system.', image: 'https://i.imgur.com/bMeRfim.png', url: 'https://flytepanel.io' },
  ];

  return (
    <div id="projects" className="projects-container">
      <h1 id="projects-title">My Projects</h1>
      <div className="projects-grid">
        {projects.map(project => (
          project.url === "#" ? (
            <div key={project.id} className="project-card">
              <img src={project.image} alt={project.title} className="project-image" />
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          ) : (
            <a key={project.id} href={project.url} className="project-card" target="_blank" rel="noopener noreferrer">
              <img src={project.image} alt={project.title} className="project-image" />
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </a>
          )
        ))}
      </div>
    </div>
  );
}

export default Projects;
